import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { Scope } from 'backend/types/scope';
import { Loader } from 'components/ui/Loader';
import { useScope } from 'hooks/useScope';

export const Paths = {
  ROOT: '/',
  REGISTRATED_LOGIN: '/connexion',
  LOGIN: '/login',
  SYNDIC_GENERAL_ASSEMBLIES: '/syndic/general-assemblies',
  SYNDIC_GENERAL_ASSEMBLY_REGISTRATION:
    '/syndic/general-assembly/:generalAssemblyId/registration',
  SYNDIC_GENERAL_ASSEMBLY_PROJECTION_MODE:
    '/syndic/general-assembly/:generalAssemblyId/projection-mode',
  SYNDIC_GENERAL_ASSEMBLY_PROJECTED_SCREEN:
    '/syndic/general-assembly/:generalAssemblyId/projected-screen',
  SYNDIC_GENERAL_ASSEMBLY: '/syndic/general-assembly/:generalAssemblyId',
  CO_OWNER_GENERAL_ASSEMBLIES: '/co-owner/general-assemblies',
  CO_OWNER_GENERAL_ASSEMBLY: '/co-owner/general-assembly/:generalAssemblyId',
};

export const DEFAULT_PAGE = Paths.SYNDIC_GENERAL_ASSEMBLIES;

const Login = lazy(() => import('pages/Login'));
const RegistratedLogin = lazy(() => import('pages/RegistratedLogin'));
const SyndicGeneralAssemblies = lazy(
  () => import('pages/Syndic/GeneralAssemblies'),
);
const SyndicGeneralAssembly = lazy(
  () => import('pages/Syndic/GeneralAssembly'),
);
const SyndicGeneralAssemblyRegistration = lazy(
  () => import('pages/Syndic/Registration'),
);
const SyndicGeneralAssemblyProjectionMode = lazy(
  () => import('pages/Syndic/ProjectionMode'),
);
const SyndicGeneralAssemblyProjectedScreen = lazy(
  () => import('pages/Syndic/ProjectedScreen'),
);
const CoOwnerGeneralAssemblies = lazy(
  () => import('pages/CoOwner/GeneralAssemblies'),
);
const CoOwnerGeneralAssembly = lazy(
  () => import('pages/CoOwner/GeneralAssembly'),
);

const DEFAULT_PAGE_BY_SCOPE: Record<Scope, string> = {
  [Scope.SUPERADMIN]: Paths.SYNDIC_GENERAL_ASSEMBLIES,
  [Scope.SYNDIC]: Paths.SYNDIC_GENERAL_ASSEMBLIES,
  [Scope.CO_OWNER]: Paths.CO_OWNER_GENERAL_ASSEMBLIES,
};

export const Routing: React.FunctionComponent = () => {
  const scope = useScope();

  // TODO-AUDIT: Here I will add a wrapper for the connected pages, this component will add generic elements for all connected views like the navbar
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          exact
          path={Paths.REGISTRATED_LOGIN}
          component={RegistratedLogin}
        />
        <Route exact path={Paths.LOGIN} component={Login} />
        <Route
          exact
          path={Paths.SYNDIC_GENERAL_ASSEMBLIES}
          component={SyndicGeneralAssemblies}
        />
        <Route
          path={Paths.SYNDIC_GENERAL_ASSEMBLY_REGISTRATION}
          component={SyndicGeneralAssemblyRegistration}
        />
        <Route
          exact
          path={Paths.SYNDIC_GENERAL_ASSEMBLY_PROJECTION_MODE}
          component={SyndicGeneralAssemblyProjectionMode}
        />
        <Route
          exact
          path={Paths.SYNDIC_GENERAL_ASSEMBLY_PROJECTED_SCREEN}
          component={SyndicGeneralAssemblyProjectedScreen}
        />
        <Route
          path={Paths.SYNDIC_GENERAL_ASSEMBLY}
          component={SyndicGeneralAssembly}
        />
        <Route
          exact
          path={Paths.CO_OWNER_GENERAL_ASSEMBLIES}
          component={CoOwnerGeneralAssemblies}
        />
        <Route
          path={Paths.CO_OWNER_GENERAL_ASSEMBLY}
          component={CoOwnerGeneralAssembly}
        />
        <Route
          exact
          path={Paths.ROOT}
          render={() =>
            scope === undefined ? (
              <Loader />
            ) : (
              <Redirect to={DEFAULT_PAGE_BY_SCOPE[scope]} />
            )
          }
        />
        <Route render={() => <Redirect to={Paths.ROOT} />} />
      </Switch>
    </Suspense>
  );
};
