import React, { useEffect } from 'react';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { BrowserRouter } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import { Auth } from 'components/utils/Auth';
import { IntlConfig } from 'components/utils/IntlConfig';
import { Routing } from 'components/utils/Routing';
import { fuego } from 'services/firebase/fuego';

import packageJson from '../package.json';

import './App.less';

const VersionFooter = () => (
  <div className="version-footer">Votim {packageJson.version}</div>
);

const App: React.FunctionComponent = () => {
  useEffect(() => {
    // Clear session storage on page unload or reload to force users back to the homepage on re-entry.
    // This ensures a user gesture is required, resolving issues like browsers blocking the AudioContext from starting automatically.
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <BrowserRouter>
      <IntlConfig>
        <FuegoProvider fuego={fuego}>
          <Auth>
            <Div100vh>
              <Routing />
              <VersionFooter />
            </Div100vh>
          </Auth>
        </FuegoProvider>
      </IntlConfig>
    </BrowserRouter>
  );
};

export default App;
